import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from 'gatsby'

import SalesBoost from '../../components/contact-boost'
import Layout from '../../layout/layout'

import Check from '../../svg/check.svg'

const Page = () => (

    <Layout>

        <Helmet>
          <title>Prijzen - Uw winkelgebied tijdelijk kosteloos online</title>
          <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="text-left full-width divider">
            <div className="inner">
                <div className="text">
                    <h2>Voor retailers</h2>
                    <p>Gebruik van Zupr is de eerste 30 dagen helemaal gratis! U kunt in deze periode alle mogelijkheden van de betaalde versie uitproberen. Daarna maakt u een keuze uit een van de volgende versies:</p>
                    <div className="double-table">
                        <table className="tarif">
                          <tbody>
                            <tr>
                                <th>Gratis versie</th>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Locatie-informatie tonen</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Assortiment tonen</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Voorraad doorgeven</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Reserveren aanbieden</td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr className="center">
                                <td><a href="/nl/app" className="btn btn-demo">Direct aanmelden</a></td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="tarif">
                          <tbody>
                            <tr>
                                <th>Betaalde versie (€260 per jaar)</th>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Locatie-informatie tonen</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Assortiment tonen</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Voorraadcheck door consument</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Reserveren aanbieden</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Click&Collect aanbieden</td>
                            </tr>
                            <tr>
                                <td><span className="check"><Check /></span>Bezorging aanbieden</td>
                            </tr>
                            <tr className="center">
                                <td><a href="/nl/app" className="btn btn-demo">Direct aanmelden</a></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>

        <section className="text-left full-width divider">
            <div className="inner">
                <div className="text">
                    <h2>Voor winkelgebieden</h2>
                    <p>Gedurende de lockdown bieden we kosteloos de basisversie van ons product aan voor winkelgebieden en haar winkeliers die nu geen online oplossing hebben om vindbaar te zijn. Binnen enkele minuten kunnen wij een online omgeving klaar zetten zodat retailers direct online gevonden worden in uw winkelgebied.</p>
                    <p>Wilt u liever een ‘customized warenhuis’ zoals <a href="https://www.warenhuisgroningen.nl/" target="_blank" rel="noopener noreferrer">Warenhuis Groningen</a>? Dat kan ook maar vraagt iets meer voorbereiding en coördinatie. Vanuit Zupr helpen wij u hier uiteraard bij.</p>
                    <div className="table">
                        <table className="tarif">
                          <tbody>
                            <tr>
                                <th width="50%">Omschrijving</th>
                                <th width="50%">Tarieven</th>
                            </tr>
                            <tr>
                                <td><strong>Opstartkosten</strong></td>
                                <td>€ 2500,-</td>
                            </tr>
                            <tr>
                                <td><strong>Onderhoud & Support platform</strong></td>
                                <td>€ 2400,- per jaar</td>
                            </tr>
                            <tr>
                                <td><strong>Abonnementskosten retailer</strong></td>
                                <td>€ 260,- per retailer per jaar</td>
                            </tr>
                            <tr>
                                <td><strong>Onboarding & Support retailers</strong></td>
                                <td>Inbegrepen</td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    <h3>Marketing & Promotie</h3>
                    <p>Het is belangrijk rekening te houden met de eventuele kosten die u maakt voor promotie van uw collectieve retailplatform. Deze zijn sterk afhankelijk van de grootte van uw winkelgebied en het gewenste bereik onder consumenten. Zupr verzorgt op lokaal niveau geen promotionele activiteiten maar kan u, indien gewenst, in contact brengen met een of meerdere marketingbureaus. In ons draaiboek "Lanceren van een Collectief Retailplatform" leest u hier meer over.</p>
                </div>
            </div>
        </section>

        <SalesBoost />

    </Layout>
)

export default Page
